import React from 'react'
import Hero from '../components/Test'

const Home = () => {
    const numbers = [1, 2, 3]
    return (
        <div>
            <Hero />


        </div>
    )
}

export default Home
